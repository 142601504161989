<template>
    <div>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printInvoice()" v-if="hasInvoice">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printInvoice(true)" v-if="officeId == 20 && salesTypeId == 5">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
        </template>
        <template v-if="updateMode && !listMode">
            <v-btn :loading="loading.url" small class="ml-2 lighten-2" v-if="hasInvoice" @click.stop="printInvoice()">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.invoice') }}</span>
            </v-btn>
            <template v-if="officeId == 20 && salesTypeId == 5">
                <v-btn
                    :loading="loading.url"
                    small
                    class="ml-2 lighten-2"
                    @click.stop="printInvoice()"
                >
                    <v-icon small>far fa-file-pdf</v-icon>
                    <span class="ml-1">{{ $t('message.invoice') + ' ' + contractCurrency + '-' + invoiceCurrency  }}</span>
                </v-btn>
                <v-btn
                    :loading="loading.urlSingleCurrency"
                    small
                    class="ml-2 lighten-2"
                    @click.stop="printInvoice(true)"
                >
                    <v-icon small>far fa-file-pdf</v-icon>
                    <span class="ml-1">{{ $t('message.invoice') + ' ' + invoiceCurrency }}</span>
                </v-btn>
            </template>
        </template>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "PrintDirectSalesInvoiceButton",
    props: ['invoiceId','updateMode','listMode','officeId','salesTypeId','destinationId','contractCurrencyId','invoiceCurrencyId'],
    data() {
        return {
            contractCurrency: 'USD',
            loading: {
                url: false,
                urlSingleCurrency: false
            },
            invoiceCurrency: 'NA',
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        hasInvoice() {
            if ((this.officeId == 1 && this.destinationId != 1 && this.salesTypeId != 5)
            || ([3,20,21,22].includes(this.officeId) && this.salesTypeId != 5)
            || ([1,3,21,22].includes(this.officeId) && this.salesTypeId == 5)
            || (this.destinationId == 1 && this.salesTypeId != 5))
            {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        getPrintPdfUrl(singleCurrency = false) {
            return new Promise((resolve, reject) => {
                let urlComponent = "/print-url/invoice/"
                if(singleCurrency) urlComponent = "/print-url/invoice-single-currency/"
                api
                    .get(urlComponent + this.invoiceId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printInvoice(singleCurrency = false) {
            if(singleCurrency){
                this.loading.urlSingleCurrency = true
            } else {
                this.loading.url = true;
            }
            this.getPrintPdfUrl(singleCurrency)
                .then(response => {
                    if(response.data.status == 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                    if(this.loading.url) this.loading.url = false
                    if(this.loading.urlSingleCurrency) this.loading.urlSingleCurrency = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    if(this.loading.url) this.loading.url = false
                    if(this.loading.urlSingleCurrency) this.loading.urlSingleCurrency = false
                })
        }
    },
    watch: {
        contractCurrencyId(value) {
            if(value){
                const contractCurrency = this.allCurrencies.find(c => c.Currency.id == this.contractCurrencyId)
                if(contractCurrency) this.contractCurrency = contractCurrency.Currency.code
            }
        },
        invoiceCurrencyId(value) {
            if(value){
                const invoiceCurrency = this.allCurrencies.find(c => c.Currency.id == this.invoiceCurrencyId)
                if(invoiceCurrency) this.invoiceCurrency = invoiceCurrency.Currency.code
            }
        }
    },
    created() {
        if(this.allCurrencies.length == 0 && this.updateMode && !this.listMode) this.getAllCurrencies()
        const invoiceCurrency = this.allCurrencies.find(c => c.Currency.id == this.invoiceCurrencyId)
        const contractCurrency = this.allCurrencies.find(c => c.Currency.id == this.contractCurrencyId)
        if(invoiceCurrency) this.invoiceCurrency = invoiceCurrency.Currency.code
        if(contractCurrency) this.contractCurrency = contractCurrency.Currency.code
    }
}
</script>

<style scoped>

</style>